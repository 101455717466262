import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        At Seha W Afeya, your privacy is our priority. This Privacy Policy
        outlines how we collect, use, and protect your personal information in
        accordance with applicable privacy laws.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Personal Identification Information"
            secondary="We collect personal details such as your name, email address, phone number, and other identifying information when you register or use our services."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Health Information"
            secondary="We collect health-related information you provide, including medical history, images, and documents for the purpose of consultation and diagnosis."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Usage Data"
            secondary="We collect information on how you interact with our app, such as pages visited, time spent, and other analytical data to improve our services."
          />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        How We Use Your Information
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="To Provide Services"
            secondary="We use your information to facilitate consultations, share documents with healthcare professionals, and provide personalized health recommendations."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Communication"
            secondary="We may use your contact information to send you notifications, reminders, and other relevant updates."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Improvement"
            secondary="Usage data helps us understand how our app is used and to make improvements to user experience."
          />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        Data Protection and Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement strict security measures to protect your personal data
        against unauthorized access, alteration, disclosure, or destruction. All
        health data shared on our platform is encrypted and stored securely.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Sharing of Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not share your personal or health information with third parties
        except as required by law or with your explicit consent. Healthcare
        professionals you consult with will have access to the information you
        share with them for the purpose of providing medical advice.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your Rights
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to access, update, or delete your personal
        information at any time. You may also withdraw your consent for us to
        use your data, although this may impact the services we can provide.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Changes to This Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy periodically. We will notify you of
        any significant changes and update the "last updated" date at the top of
        the policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about our Privacy Policy or the
        handling of your data, please contact us at info@sehawaafeya.com.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
