import React from "react";
import { Container, Typography, Box } from "@mui/material";

const DoctorPrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At Seha W Afeya, we are committed to protecting your privacy. This
          Privacy Policy outlines how we collect, use, and safeguard your
          personal information when you use the Seha W Afeya Doctor App.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide directly to us, such as when
          you create an account, request a consultation, or communicate with
          patients. This information may include your name, email address,
          medical license details, and other personal or professional
          information necessary for the operation of the app.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to:
          <ul>
            <li>Facilitate and manage patient consultations.</li>
            <li>
              Review health-related images and files for diagnosis and treatment
              planning.
            </li>
            <li>
              Communicate with you regarding updates, notifications, and
              support.
            </li>
            <li>Improve the app's functionality and user experience.</li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Information Sharing and Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          We do not share your personal information with third parties, except
          as necessary to comply with legal obligations, enforce our terms and
          conditions, or protect the rights, property, or safety of Seha W
          Afeya, our users, or others.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement industry-standard security measures to protect your
          personal information from unauthorized access, use, or disclosure. All
          data exchanges within the app are encrypted and comply with relevant
          health data protection regulations.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, update, or delete your personal
          information at any time. If you wish to exercise any of these rights,
          please contact our support team.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated revision date. Your continued
          use of the app after any changes constitutes your acceptance of the
          new policy.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy or our data
          practices, please contact us at info@sehawafeya.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default DoctorPrivacyPolicy;
